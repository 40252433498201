import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import { SocialIcon } from 'react-social-icons';

import { rhythm } from "../utils/typography"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
            style={{
              display: `flex`,
              marginBottom: rhythm(2.5),
            }}
          >
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              style={{
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
                minWidth: 50,
                borderRadius: `100%`,
              }}
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
            <div>
              <p style={{marginBottom: 0}}>
                Viết bởi <strong>Sơn</strong> về những thứ giản đơn.
              </p>
              <div style={styles.socialContainer}>
                  <SocialIcon style={styles.socialIcon} url={`https://facebook.com/${social.facebook}`}></SocialIcon>
                  <SocialIcon style={styles.socialIcon} url={`https://instagram.com/${social.instagram}`}></SocialIcon>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

const styles = {
  socialContainer: {
    marginTop: rhythm(0.28)
  },
  socialIcon: {
    height: 40,
    width: 40,
    marginLeft: rhythm(0.5),
    marginRight: rhythm(0.5)
  }
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          facebook,
          instagram
        }
      }
    }
  }
`


export default Bio
