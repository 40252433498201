import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image';
import { Box } from 'rebass';

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;
  return (
    <Layout location={location} title={siteTitle} displayNav={false}>
      <SEO title={post.frontmatter.title} description={post.excerpt} />
      <Box css={`position: relative`}>
        <Box as={Img}
          fluid={post.frontmatter.cover.childImageSharp.fluid}
          title={post.frontmatter.title}>
        </Box>
        <Box css={`
            z-index: 99;
            position: absolute;
            bottom:0
            padding-left: ${rhythm(1)};
            padding-right: ${rhythm(0.5)};
            background-color: #ffffff40
            @media (max-width: 800px) {
              display: none;
            }
          `}>
          <h1
            style={{
              marginTop: rhythm(0.4),
            }}
          >{post.frontmatter.title}
          </h1>
          <p
            css={{
              ...scale(-1 / 6),
              display: `block`,
              marginBottom: rhythm(1),
              marginTop: rhythm(-1)
            }}
          >
            {post.frontmatter.date}
          </p>
        </Box>
      </Box>
      <Box css={`
            display: none;
            margin-top: ${rhythm(0.4)}
            @media (max-width: 800px) {
              display: block;
            }
          `}>
        <h1
          style={{
            marginTop: 0,
          }}
        >{post.frontmatter.title}
        </h1>
        <p
          css={{
            ...scale(-1 / 6),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1)
          }}
        >
          {post.frontmatter.date}
        </p>
      </Box>
      <div dangerouslySetInnerHTML={{ __html: post.html }} css={`margin-top: ${rhythm(1)}; white-space: pre-wrap`} />
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
      <Bio />

      <ul
        css={`
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            list-style: none;
            padding: 0
          `}
      >
        <li>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
              </Link>
          )}
        </li>
      </ul>
    </Layout>
  )
};

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        cover {
          childImageSharp {
            ... on ImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
